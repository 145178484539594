import { connect } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ParticleEmitter } from "./packages";
import { memo, useEffect, useRef } from "react";
import { getHistoryPhysicalMiddleWare } from "./modules/HistoryPhysicalModule/store/historyPhysicalMiddleware";
import { isEmpty } from "./utils/validators";
import { AUTH_TOKEN } from "./utils/localStoreConst";
import AppProvider from "./AppProvider";
import { RootState, useAppDispatch } from "./redux/store";
import Loader from "./packages/Loader/Loader";

const mapStateToProps = (state: RootState) => {
  const { loaderReducers } = state;
  return {
    isLoader: loaderReducers.isLoader,
  };
};

type Props = {
  isLoader: boolean;
};

const AppLayout: React.FC<Props> = memo(({ isLoader }: Props) => {
  const dispatch = useAppDispatch();
  const isDispatched = useRef(false);

  useEffect(() => {
    if (!isDispatched.current && !isEmpty(localStorage.getItem(AUTH_TOKEN))) {
      dispatch(getHistoryPhysicalMiddleWare()).finally(() => {
        isDispatched.current = true;
      });
    }
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ParticleEmitter />
        {isLoader && <Loader />}
        <AppProvider />
      </LocalizationProvider>
    </>
  );
});

export default connect(mapStateToProps)(AppLayout);
