import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { Box, Typography, colors } from "@mui/material";
import { styled } from "@mui/system";
import { PRIMARY } from "../../theme/colors";
import { FONTS } from "../../utils/fontConstant";
import { isEmpty } from "../../utils/validators";

export default function UnstyledTextareaIntroduction({
  minRows = 10,
  id,
  value,
  onChange,
  placeholder,
  label,
}: {
  minRows?: number;
  id?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  label?: string;
}) {
  return (
    <Box>
      {!isEmpty(label) && (
        <Typography
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
          sx={{ mb: 1 }}
          fontSize={15}
        >
          {label}
        </Typography>
      )}
      <TextareaAutosize
        onChange={onChange}
        value={value}
        id={id}
        sx={{ width: "100%" }}
        minRows={minRows}
        aria-label="textarea"
        placeholder={placeholder}
      />
    </Box>
  );
}

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'Montserrat-Regular';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[900]};
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid ${colors.grey[600]};
  box-shadow: 0px 2px 2px ${colors.grey[900]};
  
  &:hover {
    border-color: ${colors.grey[100]};
  }

  &:focus {
    border-color: ${PRIMARY};
    box-shadow: 0 0 0 1px ${PRIMARY};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
