import {
  Box,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
  colors,
} from "@mui/material";
import { useState } from "react";
import { VideosEntity } from "../../modules/VideoModule/store/video.types";
import { FONTS } from "../../utils/fontConstant";
import { useLanguage } from "../../lang/language";
import moment from "moment";
import { GREY_TEXT_1 } from "../../theme/colors";

const { NEW_MAILBAG } = useLanguage;
type Props = {
  item: VideosEntity;
  height?: number;
  width?: number | string;
  sxContainer?: SxProps<Theme>;
  classNameIframe?: string;
  isDate?: boolean;
};

const VimeoVideo = ({
  item,
  height = 260,
  width = 400,
  sxContainer,
  classNameIframe,
  isDate,
}: Props) => {
  const [isLoader, setLoader] = useState(true);
  return (
    <Box sx={{ position: "relative", ...sxContainer }}>
      <Box
        sx={{
          position: "relative",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {isLoader && (
          <Box sx={{ position: "absolute", zIndex: 111 }}>
            <CircularProgress
              sx={{ height: 100, width: 100 }}
              color="inherit"
            />
          </Box>
        )}
        <iframe
          className={classNameIframe}
          onLoad={() => setLoader(false)}
          src={item.player_embed_url}
          height={height}
          width={width}
        />
      </Box>

      {item?.tags?.includes("mailbag") && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 99,
            left: 0,
            top: 0,
            backgroundColor: colors.red[800],
            p: 1,

            borderBottomRightRadius: 10,
          }}
        >
          <Typography fontFamily={FONTS.SOURCESANSPRO_SEMIBOLD} fontSize={14}>
            {NEW_MAILBAG}
          </Typography>
        </Box>
      )}
      <Typography
        fontFamily={FONTS.MONTSERRAT_BOLD}
        sx={{ mt: 1 }}
        fontSize={14}
      >
        {item.name}
      </Typography>
      {isDate && (
        <Typography
          fontSize={12}
          color={GREY_TEXT_1}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          {moment(item.release_time).local().format("LL")}
        </Typography>
      )}
    </Box>
  );
};

export default VimeoVideo;
