import {
  Box,
  IconButton,
  Modal as MuiModal,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import Loader from "../Loader/Loader";
import Card, { cardVariantTypes } from "../Card/Card";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

type Props = {
  open: boolean;
  close: () => void;
  loader?: boolean;
  children: ReactNode;
  padding?: number;
  closeIconEnable?: boolean;
  variant?: cardVariantTypes;
  isBackDropClose?: false;
  cardClassName?: string;
  containerStyle?: SxProps<Theme>;
};

const Modal = ({
  open,
  close,
  loader,
  children,
  closeIconEnable,
  variant = "card_4",
  isBackDropClose,
  cardClassName,
  containerStyle,
}: Props) => {
  return (
    <MuiModal
      open={open}
      onClose={() => {
        if (isBackDropClose) {
          close();
        }
      }}
    >
      <Box>
        <Card
          className={cardClassName}
          sx={{ p: 3, ...style, ...containerStyle }}
          variant={variant}
        >
          {closeIconEnable && (
            <Box
              position={"absolute"}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              zIndex={11}
              right={10}
              top={10}
            >
              <IconButton onClick={close}>
                <Close />
              </IconButton>
            </Box>
          )}
          {loader && <Loader />}
          {children}
        </Card>
      </Box>
    </MuiModal>
  );
};

export default Modal;
