import { Box, Typography } from "@mui/material";
import { IsEmptyValueType } from "./validatorsTypes";
import { ERROR, SUCCESS } from "../theme/colors";
import { FONTS } from "./fontConstant";

export const isEmpty = (value: IsEmptyValueType): boolean =>
  value === undefined || value === null || value === "";

export const isValidEmail = (value: string) => {
  if (typeof value === "string") {
    return (
      value
        .trim()
        .match(
          /^([a-zA-Z0-9_+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        ) !== null
    );
  }
  return false;
};

export const validatePassword = (value: string) => {
  // Validate length
  const isLengthValid = value?.length >= 8 && value?.length <= 12;

  // Validate uppercase
  const containsUppercase = /[A-Z]/.test(value);

  // Validate special character
  const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

  // Validate lowercase
  const containsLowercase = /[a-z]/.test(value);

  return (
    <Box sx={{ mt: 1 }}>
      {isLengthValid ? (
        <Typography
          fontSize={12}
          sx={{ mb: 0.5 }}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
          color={SUCCESS}
        >
          &#x2714; Password must be between 8-12 characters.
        </Typography>
      ) : (
        <Typography
          fontSize={12}
          sx={{ mb: 0.5 }}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
          color={ERROR}
        >
          &#x2718; Password must be between 8-12 characters.
        </Typography>
      )}

      {containsLowercase ? (
        <Typography
          fontSize={12}
          sx={{ mb: 0.5 }}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
          color={SUCCESS}
        >
          &#x2714; Password must contain at least one lowercase letter.
        </Typography>
      ) : (
        <Typography
          fontSize={12}
          sx={{ mb: 0.5 }}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
          color={ERROR}
        >
          &#x2718; Password must contain at least one lowercase letter.
        </Typography>
      )}
      {containsUppercase ? (
        <Typography
          fontSize={12}
          sx={{ mb: 0.5 }}
          fontFamily={FONTS.SOURCESANSPRO_SEMIBOLD}
          color={SUCCESS}
        >
          &#x2714; Password must contain at least one uppercase letter.
        </Typography>
      ) : (
        <Typography
          fontSize={12}
          sx={{ mb: 0.5 }}
          fontFamily={FONTS.SOURCESANSPRO_SEMIBOLD}
          color={ERROR}
        >
          &#x2718; Password must contain at least one uppercase letter.
        </Typography>
      )}

      {containsSpecialChar ? (
        <Typography
          fontFamily={FONTS.SOURCESANSPRO_SEMIBOLD}
          fontSize={12}
          color={SUCCESS}
        >
          &#x2718; Password must contain at least one special character.
        </Typography>
      ) : (
        <Typography
          fontFamily={FONTS.SOURCESANSPRO_SEMIBOLD}
          fontSize={12}
          color={ERROR}
        >
          &#x2718; Password must contain at least one special character.
        </Typography>
      )}
    </Box>
  );
};

export const NUMERIC_REGEX = /^-?[0-9,]*\.?[0-9]*$/;
