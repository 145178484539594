export const routes = {
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  PASSOWRD: "/forgetpassword",
  CHANGE_PASSWORD: "/change-password",
  CHANGE_PASSWORD_AUTH: "/change-password-auth",
  HOME: "/home",
  OTP_VERIFICATION: "/otpverification",
  LABS: "/labs",
  FORUM: "/forums",
  VIDEOS: "/videos",
  DRAI: "/dr-ai",
  MERCHANDISE_STORE: "/merchandise-store",
  DISCOURSE_SESSION_SSO: "/discourse-session-sso",
  ZOOM_MEETING_SCREEN: "/zoom-meeting/:id",
  FORUMS_PASSWORD_CHANGE: "/forums-password-change",
  ENROLL: "/enroll",
  BILLING: "/billing",
  SETTINGS: "/settings",
  SUPPORT: "/support",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-service",
  ASK_THE_DOC: "/ask-the-doc",
  MAILBAG: "/mailbag",
  PROFILE: "/profile",
  DELETE_ACCOUNT: "/delete-account",
  DELETE_ACCOUNT_USER: "/delete-account-user",
  TODO: "/todo",
  HISTORY_PHYSICAL: "/history-physical",
  ABCDS: "/abcds",
  ABCDS_ADD: "/add-abcds",
  ABCDS_EDIT: "/edit-abcds",
  ZOOM_MEETING: "/zoom-meeting",
  ABOUT_US: "/about",
  BECOME_A_PROVIDER: "/become-a-provider",
  FIND_A_PROVIDER: "/find-a-provider",
  FIND_A_PROVIDER_VIEW: "/find-a-provider/:id",
  MEDICAL_ALLERGIES: "/medical-allergies",
  HISTORY_PHYSICAL_1: "/history-physical-1",
  HISTORY_PHYSICAL_2: "/history-physical-2",
  HISTORY_PHYSICAL_3: "/history-physical-3",
  HISTORY_PHYSICAL_4: "/history-physical-4",
  HISTORY_PHYSICAL_5: "/history-physical-5",
  HISTORY_PHYSICAL_6: "/history-physical-6",
  HISTORY_PHYSICAL_7: "/history-physical-7",
  HISTORY_PHYSICAL_8: "/history-physical-8",
  HISTORY_PHYSICAL_9: "/history-physical-9",
  HISTORY_PHYSICAL_10: "/history-physical-10",
  HISTORY_PHYSICAL_11: "/history-physical-11",
  HISTORY_PHYSICAL_12: "/history-physical-12",
  HISTORY_PHYSICAL_13: "/history-physical-13",
  HISTORY_PHYSICAL_14: "/history-physical-14",
  HISTORY_PHYSICAL_15: "/history-physical-15",
  HISTORY_PHYSICAL_16: "/history-physical-16",
  HISTORY_PHYSICAL_17: "/history-physical-17",
  HISTORY_PHYSICAL_17A: "/history-physical-17A",
  HISTORY_PHYSICAL_18: "/history-physical-18",
  HISTORY_PHYSICAL_19: "/history-physical-19",
  HISTORY_PHYSICAL_20: "/history-physical-20",
  HISTORY_PHYSICAL_21: "/history-physical-21",
  HISTORY_PHYSICAL_22: "/history-physical-22",
  HISTORY_PHYSICAL_22A: "/history-physical-22A",
  HISTORY_PHYSICAL_23: "/history-physical-23",
  HISTORY_PHYSICAL_24: "/history-physical-24",
  HISTORY_PHYSICAL_25: "/history-physical-25",
  HISTORY_PHYSICAL_26: "/history-physical-26",
  HISTORY_PHYSICAL_27: "/history-physical-27",
  HISTORY_PHYSICAL_28: "/history-physical-28",
  HISTORY_PHYSICAL_29: "/history-physical-29",
  HISTORY_PHYSICAL_30: "/history-physical-30",
  HISTORY_PHYSICAL_31: "/history-physical-31",
  HISTORY_PHYSICAL_32: "/history-physical-32",
  HISTORY_PHYSICAL_33: "/history-physical-33",
  HISTORY_PHYSICAL_34: "/history-physical-34",
  HISTORY_PHYSICAL_35: "/history-physical-35",
  HISTORY_PHYSICAL_36: "/history-physical-36",
  HISTORY_PHYSICAL_37: "/history-physical-37",
  HISTORY_PHYSICAL_38: "/history-physical-38",
  HISTORY_PHYSICAL_39: "/history-physical-39",
  HISTORY_PHYSICAL_40: "/history-physical-40",
  HISTORY_PHYSICAL_41: "/history-physical-41",
  HISTORY_PHYSICAL_42: "/history-physical-42",
  HISTORY_PHYSICAL_43: "/history-physical-43",
  HISTORY_PHYSICAL_44: "/history-physical-44",
  HISTORY_PHYSICAL_45: "/history-physical-45",
  HISTORY_PHYSICAL_46: "/history-physical-46",
  HISTORY_PHYSICAL_47: "/history-physical-47",
  HISTORY_PHYSICAL_48: "/history-physical-48",
  HISTORY_PHYSICAL_49: "/history-physical-49",
  HISTORY_PHYSICAL_50: "/history-physical-50",
  HISTORY_PHYSICAL_51: "/history-physical-51",
  HISTORY_PHYSICAL_52: "/history-physical-52",
  HISTORY_PHYSICAL_53: "/history-physical-53",
  HISTORY_PHYSICAL_54: "/history-physical-54",
  HISTORY_PHYSICAL_55: "/history-physical-55",
  HISTORY_PHYSICAL_56: "/history-physical-56",
  HISTORY_PHYSICAL_57: "/history-physical-57",
  HISTORY_PHYSICAL_58: "/history-physical-58",
  HISTORY_PHYSICAL_59: "/history-physical-59",
  HISTORY_PHYSICAL_60: "/history-physical-60",
  HISTORY_PHYSICAL_61: "/history-physical-61",
  HISTORY_PHYSICAL_62: "/history-physical-62",
  HISTORY_PHYSICAL_63: "/history-physical-63",
  HISTORY_PHYSICAL_64: "/history-physical-64",
  HISTORY_PHYSICAL_65: "/history-physical-65",
  HISTORY_PHYSICAL_66: "/history-physical-66",
  HISTORY_PHYSICAL_67: "/history-physical-67",
  HISTORY_PHYSICAL_68: "/history-physical-68",
  HISTORY_PHYSICAL_69: "/history-physical-69",
  HISTORY_PHYSICAL_70: "/history-physical-70",
  HISTORY_PHYSICAL_71: "/history-physical-71",
  HISTORY_PHYSICAL_72: "/history-physical-72",
};
