import axios from "axios";
import Toast from "../packages/Toast/Toast";
import { resetStore } from "../redux/store";

export const useInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        Toast(error.response.data?.message, "LONG", "error");
        localStorage.clear();
        resetStore();
        window.location.reload();
      }
      if (error.response?.data?.error?.name === "JsonWebTokenError") {
        Toast(error.response.data?.message, "LONG", "error");
        localStorage.clear();
        resetStore();
        window.location.reload();
      } else {
        if (error.response.data?.message) {
          Toast(error.response.data?.message, "LONG", "error");
        }
      }

      return Promise.reject(error);
    }
  );
};
