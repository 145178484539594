import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import React from "react";
import { Fragment, Suspense } from "react";
import { routes } from "./routes/routesPath";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { CenterView, Loader } from "./packages";
import { DARK } from "./theme/colors";
import HistoryPhysical17A from "./modules/HistoryPhysicalModule/HistoryPhysical17A";
import HistoryPhysical22A from "./modules/HistoryPhysicalModule/HistoryPhysical22A";

// Lazy-loaded components
const SigninScreen = React.lazy(
  () => import("./modules/SigninModule/SigninScreen")
);

const ZoomMeetingScreen = React.lazy(
  () => import("./modules/AskTheDocModule/ZoomMeetingScreen")
);

const DiscourseSessionScreen = React.lazy(
  () => import("./modules/ForumModule/DiscourseSessionScreen")
);
const EnrollScreen = React.lazy(
  () => import("./modules/BecomeProviderModule/EnrollScreen")
);
const NotFoundScreen = React.lazy(() => import("./common/NotFoundScreen"));
const ForumsPasswordScreen = React.lazy(
  () => import("./modules/SettingModule/ForumsPasswordScreen")
);
const SettingScreen = React.lazy(
  () => import("./modules/SettingModule/SettingScreen")
);
const DeleteAccountScreen = React.lazy(
  () => import("./modules/DeleteAccountModule/DeleteAccountScreen")
);
const FindProviderViewScreen = React.lazy(
  () => import("./modules/FindProviderModule/FindProviderViewScreen")
);
const FindProviderScreen = React.lazy(
  () => import("./modules/FindProviderModule/FindProviderScreen")
);
const BecomeProviderScreen = React.lazy(
  () => import("./modules/BecomeProviderModule/BecomeProviderScreen")
);
const LandingScreen = React.lazy(
  () => import("./modules/LandingModule/LandingScreen")
);
const AboutUsScreen = React.lazy(
  () => import("./modules/AboutUsModule/AboutUsScreen")
);
const HistoryPhysicalExamScreen = React.lazy(
  () => import("./modules/HistoryPhysicalModule/HistoryPhysicalExamScreen")
);
const ProfileScreen = React.lazy(
  () => import("./modules/ProfileModule/ProfileScreen")
);
const CustomerSupportScreen = React.lazy(
  () => import("./modules/MailbagModule/CustomerSupportScreen")
);
const GPTScreen = React.lazy(() => import("./modules/GPTModule/GPTScreen"));
const AskTheDocScreen = React.lazy(
  () => import("./modules/AskTheDocModule/AskTheDocScreen")
);
const VideoScreen = React.lazy(
  () => import("./modules/VideoModule/VideoScreen")
);
const MyLabScreen = React.lazy(
  () => import("./modules/MyLabModule/MyLabScreen")
);
const ForumScreen = React.lazy(
  () => import("./modules/ForumModule/ForumScreen")
);
const ChangePasswordScreen = React.lazy(
  () => import("./modules/SigninModule/ChangePasswordScreen")
);
const TermsServiceScreen = React.lazy(
  () => import("./modules/PrivacyPolicyModule/TermsServiceScreen")
);
const PrivacyPolicyScreen = React.lazy(
  () => import("./modules/PrivacyPolicyModule/PrivacyPolicyScreen")
);
const BillingScreen = React.lazy(
  () => import("./modules/BillingModule/BillingScreen")
);
const AddDetailsScreen = React.lazy(
  () => import("./modules/ABCDsModule/AddDetailsScreen")
);
const ABCDsScreen = React.lazy(
  () => import("./modules/ABCDsModule/ABCDsScreen")
);
const TodoScreen = React.lazy(() => import("./modules/TodoModule/TodoScreen"));
const ResponsiveDrawer = React.lazy(() => import("./common/ResponsiveDrawer"));
const SignUpScreen = React.lazy(
  () => import("./modules/SignUpModule/SignUpScreen")
);
const ForgotPasswordScreen = React.lazy(
  () => import("./modules/SigninModule/ForgotPasswordScreen")
);
const HomeScreen = React.lazy(() => import("./modules/HomeModule/HomeScreen"));
const OtpVerificationScreen = React.lazy(
  () => import("./modules/SigninModule/OtpVerificationScreen")
);

const historyPhysicalModules = Array.from({ length: 72 }, (_, i) =>
  React.lazy(
    () => import(`./modules/HistoryPhysicalModule/HistoryPhysical${i + 1}`)
  )
);

const Layout = ({ isNav }: { isNav?: boolean }) => {
  return (
    <>
      {isNav ? (
        <CenterView>
          <ResponsiveDrawer>
            <Outlet />
          </ResponsiveDrawer>
        </CenterView>
      ) : (
        <CenterView>
          <Outlet />
        </CenterView>
      )}
    </>
  );
};

const AppProvider = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader backgroundColor={DARK} />}>
        <Router basename="/">
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<LandingScreen />} />
              <Route
                path={routes.DISCOURSE_SESSION_SSO}
                element={<DiscourseSessionScreen />}
              />
              <Route
                path={routes.ZOOM_MEETING_SCREEN}
                element={<ZoomMeetingScreen />}
              />
              <Route path={routes.SIGNIN} element={<SigninScreen />} />
              <Route
                path={routes.PASSOWRD}
                element={<ForgotPasswordScreen />}
              />
              <Route
                path={routes.OTP_VERIFICATION}
                element={<OtpVerificationScreen />}
              />
              <Route path={routes.SIGNUP} element={<SignUpScreen />} />
              <Route
                path={routes.PRIVACY_POLICY}
                element={<PrivacyPolicyScreen />}
              />
              <Route path={routes.ABOUT_US} element={<AboutUsScreen />} />
              <Route
                path={routes.TERMS_OF_SERVICE}
                element={<TermsServiceScreen />}
              />
              <Route
                path={routes.CHANGE_PASSWORD}
                element={<ChangePasswordScreen />}
              />
              <Route
                path={routes.DELETE_ACCOUNT}
                element={<DeleteAccountScreen isUser={false} />}
              />
              <Route
                path={routes.BECOME_A_PROVIDER}
                element={<BecomeProviderScreen />}
              />
              <Route path={routes.ENROLL} element={<EnrollScreen />} />
              <Route
                path={routes.FIND_A_PROVIDER}
                element={<FindProviderScreen />}
              />
              <Route
                path={routes.FIND_A_PROVIDER_VIEW}
                element={<FindProviderViewScreen />}
              />
            </Route>

            <Route element={<Layout isNav />}>
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<LandingScreen />} />
                <Route
                  path={routes.DELETE_ACCOUNT_USER}
                  element={<DeleteAccountScreen isUser />}
                />
                <Route
                  path={routes.CHANGE_PASSWORD_AUTH}
                  element={<ChangePasswordScreen isProtected />}
                />
                <Route path={routes.HOME} element={<HomeScreen />} />
                <Route path={routes.BILLING} element={<BillingScreen />} />
                <Route path={routes.ABCDS} element={<ABCDsScreen />} />
                <Route path={routes.ABCDS_ADD} element={<AddDetailsScreen />} />
                <Route
                  path={routes.ABCDS_EDIT}
                  element={<AddDetailsScreen />}
                />
                <Route path={routes.TODO} element={<TodoScreen />} />
                <Route path={routes.LABS} element={<MyLabScreen />} />
                <Route path={routes.FORUM} element={<ForumScreen />} />
                <Route path={routes.VIDEOS} element={<VideoScreen />} />
                <Route
                  path={routes.ASK_THE_DOC}
                  element={<AskTheDocScreen />}
                />
                <Route path={routes.DRAI} element={<GPTScreen />} />
                <Route
                  path={routes.SUPPORT}
                  element={<CustomerSupportScreen />}
                />
                <Route path={routes.PROFILE} element={<ProfileScreen />} />
                <Route path={routes.SETTINGS} element={<SettingScreen />} />
                <Route
                  path={routes.BECOME_A_PROVIDER}
                  element={<BecomeProviderScreen />}
                />
                <Route
                  path={routes.FIND_A_PROVIDER}
                  element={<FindProviderScreen />}
                />
                <Route
                  path={routes.FIND_A_PROVIDER_VIEW}
                  element={<FindProviderViewScreen />}
                />
                <Route
                  path={routes.HISTORY_PHYSICAL}
                  element={<HistoryPhysicalExamScreen />}
                />
                {historyPhysicalModules.map((Component, i) => (
                  <Route
                    key={i}
                    path={`/history-physical-${i + 1}`}
                    element={<Component />}
                  />
                ))}
                <Route
                  path={`/history-physical-17A`}
                  element={<HistoryPhysical17A />}
                />
                <Route
                  path={`/history-physical-22A`}
                  element={<HistoryPhysical22A />}
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </Router>
      </Suspense>
    </Fragment>
  );
};

export default AppProvider;
