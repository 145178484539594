import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_USER_MEDICAL, POST_USER_MEDICAL } from "../../../redux/actions";
import {
  userMedicalParametersApi,
  userMedicalParametersV2Api,
} from "../../../routes/apiRoutes";

// export const postUserMedicalParametersMiddleWare = createAsyncThunk(
//   POST_USER_MEDICAL,
//   async (
//     {
//       value,
//       key,
//     }: {
//       key:
//         | 'a1c'
//         | 'bloodPressure'
//         | 'cholesterol'
//         | 'CAC'
//         | 'echocardiogram'
//         | 'depositionDisease'
//         | 'screenings';
//       value: any;
//     },
//     {rejectWithValue},
//   ) => {
//     try {
//       const {data} = await axios.post(userMedicalParametersApi, {
//         value,
//         key,
//       });
//       return data;
//     } catch (error: any) {
//       const typedError = error as Error;
//       return typedError;
//     }
//   },
// );

export const postUserMedicalParametersMiddleWare = createAsyncThunk(
  POST_USER_MEDICAL,
  async (
    {
      payload,
    }: {
      payload: any;
    },
    {}
  ) => {
    try {
      const { data } = await axios.post(userMedicalParametersV2Api, payload);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getUserMedicalParametersMiddleWare = createAsyncThunk(
  GET_USER_MEDICAL,
  async (_a, {}) => {
    try {
      const { data } = await axios.get(userMedicalParametersApi);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
