import axios from "axios";
import {
  getClinicsApi,
  getClinicsIDApi,
  getTestosteronologistApi,
  testosteronologistConnectApi,
} from "../../../routes/apiRoutes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CONNECT_TESTOSTERONOLOGIST,
  GET_CLINICS,
  GET_CLINICS_ID,
  GET_TESTOSTERONOLOGIST,
} from "../../../redux/actions";

export const getClinicsMiddleWare = createAsyncThunk(
  GET_CLINICS,
  async ({
    page,
    pageSize,
    country,
    state,
  }: {
    page: number;
    pageSize: number;
    country: string;
    state: string;
  }) => {
    try {
      const { data } = await axios.post(getClinicsApi, {
        page,
        pageSize,
        country,
        state,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getClinicsIDMiddleWare = createAsyncThunk(
  GET_CLINICS_ID,
  async ({ id, country, state }: { id: any; country: any; state: any }) => {
    try {
      const { data } = await axios.post(getClinicsIDApi(id), {
        country,
        state,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getTestosteronologistMiddleWare = createAsyncThunk(
  GET_TESTOSTERONOLOGIST,
  async ({ id, country, state }: { id: any; country: any; state: any }) => {
    try {
      const { data } = await axios.post(getTestosteronologistApi(id), {
        country,
        state,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const testosteronologistConnectMiddleWare = createAsyncThunk(
  CONNECT_TESTOSTERONOLOGIST,
  async ({
    clinicId,
    userId,
    firstName,
    lastName,
    email,
    phoneNumber,
  }: {
    clinicId: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }) => {
    try {
      const { data } = await axios.post(
        testosteronologistConnectApi(clinicId, userId),
        {
          firstName,
          lastName,
          email,
          phoneNumber,
        }
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
