import { Box, Button, Typography } from "@mui/material";
import { ReactNode } from "react";
import { FONTS } from "../utils/fontConstant";
import { PRIMARY } from "../theme/colors";
import styles from "./hpcontainer.module.scss";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../lang/language";
import { routes } from "../routes/routesPath";

const { UPGRADE_TO_PRO_PLAN } = useLanguage;
type Props = {
  children: ReactNode;
  handleSubmit?: () => void;
  // handleLearnMore?: () => void;
  buttonTitle?: string;
  title?: string;
  disabledSubmit?: boolean;
};
const HPContainer = ({
  children,
  handleSubmit,
  // handleLearnMore,
  buttonTitle = "Continue",
  title,
  disabledSubmit,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Box className={styles.overAll}>
      <Typography
        fontSize={16}
        color={PRIMARY}
        fontFamily={FONTS.MONTSERRAT_BOLD}
      >
        {title}
      </Typography>
      <br />
      {children}
      <br />
      <br />
      <Button
        onClick={() => navigate(routes.BILLING)}
        sx={{ textTransform: "capitalize" }}
        variant="contained"
        color="secondary"
      >
        {UPGRADE_TO_PRO_PLAN}
      </Button>
      <br />
      <br />
      <Box display="flex" flexDirection={"row"} justifyContent={"flex-end"}>
        {typeof handleSubmit === "function" && (
          <Button
            disabled={disabledSubmit}
            // color={
            //   typeof handleLearnMore !== "function" ? "primary" : "secondary"
            // }
            onClick={handleSubmit}
            size="large"
            variant="contained"
            sx={{ textTransform: "capitalize" }}
          >
            {buttonTitle}
          </Button>
        )}
        {/* {typeof handleLearnMore === "function" && (
          <Button
            onClick={handleLearnMore}
            size="large"
            variant="contained"
            sx={{ textTransform: "capitalize", ml: 2 }}
          >
            Learn More
          </Button>
        )} */}
      </Box>
    </Box>
  );
};

export default HPContainer;
