import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CHECK_EMAIL,
  CHECK_USER_NAME,
  GET_PAYMENT_BY_ID,
  SIGNUP,
  STRIPE_SUBSCRIPTIONS_CANCEL,
  SUBSCRIBE,
} from "../../../redux/actions";
import {
  checkEmailApi,
  checkUserNameApi,
  getPaymentbyIdApi,
  signUpApi,
  stripeSubscriptionsCancelApi,
  subscribeApi,
} from "../../../routes/apiRoutes";
import axios from "axios";

export const stripeSubscriptionsMiddleWare = createAsyncThunk(
  STRIPE_SUBSCRIPTIONS_CANCEL,
  async (_a) => {
    try {
      const { data } = await axios.post(stripeSubscriptionsCancelApi);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const signUpMiddleWare = createAsyncThunk(
  SIGNUP,
  async ({
    firstName,
    lastName,
    password,
    userName,
    email,
    gender,
  }: {
    firstName: string;
    password: string;
    lastName: string;
    userName: string;
    email: string;
    gender: string;
  }) => {
    try {
      const { data } = await axios.post(signUpApi, {
        firstName,
        password,
        lastName,
        userName,
        email,
        gender,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const checkEmailMiddleWare = createAsyncThunk(
  CHECK_EMAIL,
  async ({ email }: { email: string }) => {
    try {
      const { data } = await axios.get(checkEmailApi(email));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const checkUserNameMiddleWare = createAsyncThunk(
  CHECK_USER_NAME,
  async ({ userName }: { userName: string }) => {
    try {
      const { data } = await axios.get(checkUserNameApi(userName));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const subscribeMiddleWare = createAsyncThunk(SUBSCRIBE, async (_a) => {
  try {
    const { data } = await axios.post(subscribeApi);
    return data;
  } catch (error: any) {
    const typedError = error as Error;
    return typedError;
  }
});

export const getPaymentbyIdMiddleWare = createAsyncThunk(
  GET_PAYMENT_BY_ID,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.get(getPaymentbyIdApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
